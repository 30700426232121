// Imports
// ------
import React, { useEffect, useState } from 'react';
import Slider from './Slider';
import StaticImage from './StaticImage';
import Video from './Video';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Title, Caption } from './styles';

// Component
// ------
function Hero({ title, heroChooser }) {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(true);
		}, 750);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket padTop isLoaded={isLoaded} type={heroChooser[0].type}>
			<Row isExpanded sidePad>
				<Column mpad>
					<Title>
						<span>{title[0]}</span>
						<span>{title[1]}</span>
					</Title>
				</Column>
			</Row>

			<Row isExpanded sidePad>
				<Column mpad>
					{heroChooser.map(({ type, id, before, after, image, alt, video }) => {
						if (type === 'DatoCmsComparisonSlider')
							return (
								<React.Fragment key={id + type}>
									<Slider before={before} after={after} />
								</React.Fragment>
							);

						if (type === 'DatoCmsStaticImage')
							return (
								<React.Fragment key={id + type}>
									<StaticImage image={image} alt={alt} />
								</React.Fragment>
							);

						if (type === 'DatoCmsVideo')
							return (
								<React.Fragment key={id + type}>
									<Video url={video.url} />
								</React.Fragment>
							);
					})}
				</Column>
			</Row>

			<Caption weight='semi'>
				<span>M–XR is pioneering 3D Capture</span>
			</Caption>
		</Jacket>
	);
}

export default Hero;
