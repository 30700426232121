// Imports
// ------
import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ReactPlayer from 'react-player/file';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Video = ({ url }) => {
	const bp = useBreakpoint();

	return (
		<Jacket>
			<ReactPlayer
				url={url}
				playing={bp.large ? false : true}
				loop
				muted
				width={`100%`}
				height={`100%`}
				controls={false}
			/>
		</Jacket>
	);
};

export default Video;
