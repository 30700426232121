// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, grad, Section, H2, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: ${props.theme.black};

		.steps {
		}
	`
);

export const Title = styled(H2)(
	(props) => css`
		margin: 0 0 2.4rem;

		${breakup.large`
			margin: 0 0 3.6rem;
		`}
	`
);

export const Text = styled(P)(
	(props) => css`
		margin: 0 0 4.8rem;

		${breakup.large`
			margin: 0 0 6rem;
		`}
	`
);

export const Steps = styled.div(
	(props) => css`
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		width: 100%;
		overflow: scroll;
		padding: 0 1.2rem 1.2rem;

		${breakup.large`
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            padding: 0 2.4rem;
        `}

		${breakup.xxlarge`
            padding: 0 0 0 2.4rem;
        `}

		&:before {
			${grad}
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: calc(100% * 2.75);
			height: 8.4rem;

			${breakup.large`
                width: auto;
                left: 1.2rem;
                right: 0;
                height: 9.6rem;
            `}

			${breakup.xxlarge`
                left: 1.2rem;
                right: 0;
            `}
		}
	`
);
