// Imports
// ------
import React from 'react';
import Step from './Step';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Title, Text, Steps } from './styles';

// Component
// ------
function Process({ title, text, steps }) {
	return (
		<Jacket padTop>
			<Row isExpanded sidePad className='title'>
				<Column small={12} large={5} mpad>
					<Title>{title}</Title>
				</Column>
			</Row>

			<Row isExpanded sidePad>
				<Column small={12} large={6} mpad>
					<Text>{text}</Text>
				</Column>
			</Row>

			<Row isExpanded isCollapsed sidePad className='steps'>
				<Column small={12} xlarge={11} xxlarge={10} mpad>
					<Steps>
						{steps.map(({ title, desc }, i) => (
							<Step key={title} number={i + 1} heading={title} content={desc} />
						))}
					</Steps>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Process;
