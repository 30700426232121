// Imports
// ------
import React, { useState, useEffect } from 'react';
import themeStyles from '@theme';
import loadable from '@loadable/component';

// Lazy
// ------
const ImageSlider = loadable(() => import('react-image-comparison-slider'));

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Slider = ({ before, after }) => {
	// NOTE • States
	const [isSliderShown, setIsSliderShown] = useState(true);

	console.log(!before ? before.url : null);

	const sliderProps = {
		image1: before ? before.url : null,
		image2: after ? after.url : null,
		sliderWidth: 2,
		sliderColor: `white`,
		handleColor: themeStyles.bc1,
		handleBackgroundColor: `white`,
		showPlaceholder: true,
	};

	// NOTE • When the NPM package doesnt support resizes...
	useEffect(() => {
		const reinit = () => {
			setIsSliderShown(false);
			setIsSliderShown(true);
		};

		window.addEventListener('resize', reinit);

		return () => {
			window.removeEventListener('resize', reinit);
		};
	}, []);

	return <Jacket>{isSliderShown && <ImageSlider {...sliderProps} />}</Jacket>;
};

export default Slider;
