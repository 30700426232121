// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/3DCapture';
import Process from '@parts/Process';
import Pilot from '@parts/Pilot';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Technology({ transitionStatus, entry, exit, data }) {
	const ts = transitionStatus;
	const bp = useBreakpoint();

	const {
		pageTitle1,
		pageTitle2,
		heroImageBefore,
		heroImageAfter,
		processTitle,
		processText,
		processSteps,
		pilotContent,
	} = data.page;

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo data={data.page.seo} backupData={data.globalSeo} />
			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero
					title={[pageTitle1, pageTitle2]}
					heroChooser={data.page.heroChooser}
				/>
				<Process steps={processSteps} title={processTitle} text={processText} />
				<Pilot content={pilotContent} />
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Technology;

// GraphQL
// ------
export const query = graphql`
	query technology {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		page: datoCmsThreedCapture {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			pageTitle1: pageTitle
			pageTitle2: pageTitleLine2

			heroChooser {
				type: __typename

				... on DatoCmsComparisonSlider {
					id: originalId
					before: beforeImage {
						url
					}
					after: afterImage {
						url
					}
				}
				... on DatoCmsVideo {
					video {
						url
					}
				}
				... on DatoCmsStaticImage {
					image {
						data: gatsbyImageData
						alt
					}
				}
			}

			processTitle
			processText
			processSteps {
				title
				desc: description
			}

			pilotContent
		}
	}
`;
