// Imports
// ------
import React, { useState } from 'react';
import HTMLParser from '@parts/HTMLParser';
import Icon from '@icons';
import axios from 'axios';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
	Jacket,
	Participation,
	Deck,
	Heading,
	Content,
	Form,
	FormSet,
	Button,
	Success
} from './styles';

// Component
// ------
function Pilot({ content }) {
	// NOTE • States
	const [formData, setFormData] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [emailTest, setEmailTest] = useState('');
	const [mailStatus, setMailStatus] = useState('');

	// NOTE • Handlers
	const handleChange = (e) => {
		const validEmailRegex = RegExp(
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		);

		if (e.target.type === 'email') {
			setEmailTest({ value: e.target.value });
			const test = validEmailRegex.test(emailTest.value);
			setEmailError(!test);
		}

		setTimeout(() => {
			if (e.target.matches(':-internal-autofill-selected')) {
				setEmailTest({ value: e.target.value });
				const test = validEmailRegex.test(emailTest.value);
				setEmailError(!test);
			}

			clearTimeout();
		}, 250);

		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// NOTE • Submission
	const handleSubmit = (e) => {
		e.preventDefault();

		function encode(data) {
			return Object.keys(data)
				.map(
					(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
				)
				.join('&');
		}

		const axiosOptions = {
			url: window.location.href,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: encode({ 'form-name': e.target.getAttribute('name'), ...formData }),
		};

		axios(axiosOptions)
			.then((response) => {
				setMailStatus(true);
			})
			.catch((err) => alert(err));
	};

	// NOTE • Form Props
	const formProps = {
		name: 'Pilot Deck',
		method: 'POST',
		onSubmit: handleSubmit,
	};

	const closeStatus = () => {
		setMailStatus(!mailStatus);
	};

	return (
		<Jacket padTop padBottom>
			<Row isExpanded isCollapsed sidePad>
				<Column>
					<Row isExpanded isCollapsed className='container' equal>
						<Column small={12} large={6} xlarge={5} mpad>
							<Participation>
								<Heading weight='bold'>Pilot Participation</Heading>

								<Content>
									<HTMLParser toParse={content} />
								</Content>
							</Participation>
						</Column>

						<Column small={12} large={6} pushOnXlarge={1} mpad>
							<Deck>
								<Heading weight='bold'>Pilot Participation</Heading>

								<Form
									data-netlify='true'
									netlify-honeypot='bot-field'
									{...formProps}>
									<input type='hidden' name='bot-field' />
									<input type='hidden' name='form-name' value='Pilot Desk' />

									<FormSet>
										<input
											type='text'
											name='Full Name'
											placeholder='Full Name'
											onChange={handleChange}
										/>
									</FormSet>

									<FormSet>
										<input
											type='text'
											name='Company'
											placeholder='Company *'
											onChange={handleChange}
										/>
									</FormSet>

									<FormSet>
										<input
											type='email'
											name='Email'
											placeholder='Email *'
											onChange={handleChange}
										/>
									</FormSet>

									<Button className="ghost">
										<span>Submit</span>
										<Icon type='chevron-right' />
									</Button>
								</Form>

								{mailStatus && (
									<Success>
										Your request has been received. We will contact you shortly.
										<button onClick={closeStatus}><Icon type='close' /></button>
									</Success>
								)}
							</Deck>
						</Column>
					</Row>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Pilot;
