// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup } from '@tackl';
import { motion } from 'framer-motion';

// Exports
// ------
export const Jacket = styled(motion.div)(
	(props) => css`
		position: relative;
		z-index: 5;
		width: 100%;
		height: 50vh;
		background: ${props.theme.bc3};
		overflow: hidden;

		${breakup.large`
			min-height: 60rem;
			max-height: 60rem;
		`}

		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			left: -2.4rem;
			right: -2.4rem;
			bottom: 0;
			height: 50%;
			background: ${props.theme.black};

			${breakup.large`
				left: -15vw;
				right: -15vw;
			`}

			${breakup.huge`
				left: -15vw;
				right: -15vw;
			`}
		}

		img {
			object-fit: cover;
		}

		.slider {
			position: relative;
			z-index: 2;

			&__wrapper {
				overflow: visible;
			}

			&__stick {
				height: auto;
				top: -1.2rem;
				bottom: -1.2rem;

				${breakup.large`
					top: -2.4rem;
					bottom: -2.4rem;
				`}
			}

			&__handle {
				position: relative;
				width: 4.8rem;
				height: 4.8rem;
				border-radius: 100%;
				background: ${props.theme.white};

				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAWBAMAAAB9MqLlAAAAMFBMVEUAAAAxFs4wFMsxFs4wFMsxFs4xFs4wEM8wFMsxFc8yFs0xFs8xFc4wFc0xFc8xFs64v+XCAAAAD3RSTlMAgCDfMO/PEEC/r6CQYF+/dXfFAAAAsUlEQVQoz4WSzQnCQBSEnyIkkBzSgUIgV0FyN2ABlhCwgpSQTizBlGAnliD+5KCgn5AIbwgSv8My7DzYndm1ntqUmejJxZR5IppEB/HJCK5ibeH4lcGettajSp5VLzPITUlh14npmttCDNnZ9DNKBiszC0se1cAKDrSF2RkaGxLD2yZICk3KmGUnD+hE8PKAjpeQ/rx8PhLZAzp9CRrQ0RJiuIu1hGb0Kf9/ACtMCbv1A5hlYMQd4U6GAAAAAElFTkSuQmCC');
				background-size: 2.6rem 1rem;
				background-repeat: no-repeat;
				background-position: center center;
				transform-origin: center center;

				svg {
					display: none;
				}

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 6rem;
					height: 6rem;
					border-radius: 50%;
					border: solid white;
					opacity: 0.8;

					transform: translate(-50%, -50%) scale(0.8);
					transform-origin: center;
					border-width: 1px;

					animation: ${Pulse} infinite;
					animation-duration: 2s;
				}

				&:before {
					animation-delay: 0.25s;
				}
				&:after {
					animation-delay: 0.75s;
				}
			}
		}
	`
);

const Pulse = keyframes`
	100% {
		transform: translate(-50%, -50%) scale(1.6);
		opacity: 0;
	}
`;
