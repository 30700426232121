// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, H3, P } from '@tackl';
import { h1Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		position: relative;
		z-index: 1;
		width: 100%;
		min-width: 75%;
		margin: 8.4rem 0 0 0;
		padding: 6rem 1.2rem 0;

		${breakup.large`
            width: calc(100% - 1.2rem);
            margin: 9.6rem 0 0 0;
        `}
	`
);

export const Number = styled.span(
	(props) => css`
		${h1Styles}
		position: absolute;
		top: 0;
		left: 1.2rem;
		transform: translateY(-50%);

		font-family: ${props.theme.heading};
		font-weight: ${props.theme.bold};
		font-size: 6rem;
		line-height: 7rem;
	`
);

export const Heading = styled(H3)(
	(props) => css`
		padding: 0 0 2.4rem;
	`
);

export const Content = styled(P)(
	(props) => css`
		//
	`
);
