// Imports
// ------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const StaticImage = ({ image }) => (
	<Jacket>
		<GatsbyImage
			image={image.data}
			alt={image.alt ? image.alt : 'Technology at M–XR'}
		/>
	</Jacket>
);

export default StaticImage;
