// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Em, grad } from '@tackl';
import { emStyles, pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: ${props.theme.black};

		.container {
			border-radius: 4px;
			overflow: hidden;

			${breakup.large`
                background: ${props.theme.bc3};
            `}
		}
	`
);

export const Participation = styled.div(
	(props) => css`
		padding: 6rem 2.4rem 3rem;
		margin: 0 2.4rem;
		background: ${props.theme.bc3};

		${breakup.large`
            padding: 6rem;
            height: 100%;
        `}
	`
);

export const Deck = styled.div(
	(props) => css`
		padding: 3rem 2.4rem 6rem;
		margin: 0 2.4rem;
		background: ${props.theme.bc3};

		${breakup.large`
            padding: 6rem;
            height: 100%;
        `}
	`
);

export const Heading = styled(Em)(
	(props) => css`
		margin: 0 0 3.6rem;

		${breakup.large` margin: 0 0 7.2rem; `}
	`
);

export const Content = styled.div(
	(props) => css`
		* {
			margin-bottom: 2.4rem;

			&:last-child {
				margin: 0;
			}
		}

		p {
			${pStyles}
		}

		ul {
			li {
				${pStyles}
				margin-bottom: 1.2rem;

				&:last-child {
					margin: 0;
				}

				p {
					margin: 0;
				}
			}
		}
	`
);

export const Form = styled.form(
	(props) => css`
		display: flex;
		flex-flow: column;

		button {
			margin-left: auto;
			margin-top: 2.4rem;
		}
	`
);

export const FormSet = styled.div(
	(props) => css`
		margin-bottom: 3.6rem;

		input {
			${pStyles}
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			padding: 0;

			background: transparent;
			border-bottom: 1px solid ${props.theme.white};
			height: 4.8rem;
			color: ${props.theme.white};
			transition: ${props.theme.ease};

			&::placeholder {
				${emStyles}
				color: ${props.theme.white};
				text-transform: uppercase;
				transition: all 1s ${props.theme.bezzy};
			}

			&:focus,
			&:active {
				&::placeholder {
					text-indent: -1000rem;
				}
			}
		}
	`
);


export const Button = styled.button(
	(props) => css`
	${grad}
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;

	width: max-content;
	padding: 0 1.8rem;
	transition: ${props.theme.ease};
	overflow: hidden;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: ${props.theme.ease};
	}

	&:before {
		z-index: 1;
		background: linear-gradient(to right, transparent, white, transparent);
		left: 0;
		right: auto;
		width: 100%;
		transform: translateX(-100%);
	}

	&:after {
		z-index: 2;
		background: ${props.theme.bc1};
		opacity: 0;
	}

	&:hover {
		// transform: perspective(50rem) rotateY(-15deg);

		&:before {
			transform: translateX(100%);
		}
		&:after {
			opacity: 1;
		}

		span {
			text-shadow: 1px 12px 5px rgba(0, 0, 0, 0.12);
		}
	}

	&:focus,
	&:active {
		// transform: perspective(500px) rotateY(15deg);
	}

	// NOTE • Label
	span {
		display: inline-block;
		position: relative;
		z-index: 3;
		font-family: ${props.theme.body};
		font-size: 1.2rem;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: ${props.theme.ease};
	}

	// NOTE • Icon
	svg {
		display: inline-block;
		position: relative;
		z-index: 3;
		margin: 0 0 0 1.2rem;
		width: 1.6rem;
		height: 1.6rem;
		fill: ${props.theme.white};
		transition: ${props.theme.ease};
	}

	// NOTE • If ghost class is applied
	&.ghost {
		background: transparent;
		border: 1px solid ${props.theme.white};

		&:hover {
			&:after {
				background: ${props.theme.white};
			}

			span {
				color: ${props.theme.bc1};
			}

			svg {
				fill: ${props.theme.bc1};
			}
		}
	}

	&.icon {
		padding: 0 1.2rem 0 1.8rem;
	}

		height: 4.8rem;
		cursor: pointer;
	`
);


export const Success = styled.div((props => css`
	position: absolute;
	bottom: 0; left: 0; right: 0;
	z-index: 2;

	display: flex;
	padding: 2.4rem;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	font-family: ${props.theme.body};
	font-size: 1.4rem;

	&:before {
		${grad}
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	button {
		background: none;
		cursor: pointer;

		svg {
			stroke: white;
		}
	}
`));