// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, Number, Heading, Content } from './styles';

// Component
// ------
function Step({ heading, content, number }) {
	return (
		<Jacket>
			<Number>{number}</Number>
			<Heading>{heading}</Heading>
			<Content>{content}</Content>
		</Jacket>
	);
}

export default React.memo(Step);
