// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, grad, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		background: linear-gradient(-90deg, #303339 -100%, #080809 50%);

		&:before {
			${grad}
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			height: 72%;

			transform: ${props.isLoaded ? `scaleY(1);` : `scaleY(0);`};
			transform-origin: top center;
			transition: 1.25s ${props.theme.bezzy};
			transition-delay: 0.25s;

			${breakup.smedium` height: 72%; `}
			${breakup.medium` height: 68%; `}
			${breakup.xlarge` height: 68%; `}
			${breakup.xxlarge` height: 68%; `}
		}
	`
);

export const Title = styled(H1)(
	(props) => css`
		position: relative;
		z-index: 2;
		padding-top: ${props.theme.padL};
		padding-bottom: 6rem;

		span {
			display: block;
		}
	`
);

export const Caption = styled(Em)(
	(props) => css`
		display: flex;
		justify-content: flex-start;
		background: ${props.theme.black};
		padding: 3.6rem 0 0 2.4rem;

		${breakup.medium` padding: 3.6rem 0 0 6rem; `}

		${breakup.large`
			justify-content: flex-end;
			padding: 2.4rem 6rem 0 0;
		`}

		${breakup.xlarge` padding: 2.4rem 8.4rem 0 0; `}
		${breakup.xxlarge` padding: 2.4rem 10rem 0 0;`}
		${breakup.huge` padding: 2.4rem calc(8vw + 1.2rem) 0 0;`}
		${breakup.uber` padding: 2.4rem calc(12vw + 1.2rem) 0 0;`}
		

		span {
			position: relative;
			padding: 0 0 0.8rem 0; // -2px height for 6px padding

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 2px;
				background: ${props.theme.bc1};
			}
		}
	`
);
